import Typography from '@mui/material/Typography'
import Box, { BoxProps } from '@mui/material/Box'
import Link from 'next/link'
import Button from '@mui/material/Button'
import * as React from 'react'
import { styled } from '@mui/material/styles'
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined'
import DescriptionIcon from '@mui/icons-material/Description'

const BoxWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.down('md')]: {
    maxWidth: 400
  }
}))

const LoginPageNotReady = () => {
  console.log(window.location.origin)

  function createLinkForImpressum() {
    if (process.env.NEXT_PUBLIC_ENVIRONMENT !== 'live') {
      return `https://${process.env.NEXT_PUBLIC_ENVIRONMENT}.mybestbrands.com/it/about/note-legali-condizioni-generali-protezione-dati-personali/`
    } else return `https://www.mybestbrands.com/it/about/note-legali-condizioni-generali-protezione-dati-personali/`
  }

  return (
    <Box className='content-center' sx={{ backgroundColor: 'background.paper' }}>
      <Box
        sx={{
          p: 7,
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'background.paper'
        }}
      >
        <BoxWrapper>
          <Box
            sx={{
              mb: 6,
              mt: 6,
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Typography sx={{ color: 'black', textAlign: 'center', mb: 10 }}>
              Welcome to MYBESTBRANDS Partnerweb.
            </Typography>
            <Typography sx={{ color: 'black', textAlign: 'center' }}>
              Your key account manager will inform you,
            </Typography>
            <Typography sx={{ color: 'black', textAlign: 'center' }}>as soon as your access is ready.</Typography>
          </Box>
          <Box sx={{ mt: 10 }}>
            <Link legacyBehavior target='_blank' href={`${window.location.origin}/static/tac-partner-it.pdf`}>
              <a style={{ textDecoration: 'none' }} target='_blank' rel='noopener noreferrer'>
                <Button variant={'text'} startIcon={<DescriptionIcon />}>
                  Terms&Conditions
                </Button>
              </a>
            </Link>
            <Link legacyBehavior target='_blank' href={createLinkForImpressum()}>
              <a style={{ textDecoration: 'none' }} target='_blank' rel='noopener noreferrer'>
                <Button variant={'text'} startIcon={<CreateOutlinedIcon />}>
                  Impressum
                </Button>
              </a>
            </Link>
          </Box>
        </BoxWrapper>
      </Box>
    </Box>
  )
}

export default LoginPageNotReady
