import { useState } from 'react'

import Link from 'next/link'
import Image from 'next/legacy/image'

import MuiLink from '@mui/material/Link'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import IconButton from '@mui/material/IconButton'
import Box, { BoxProps } from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import OutlinedInput from '@mui/material/OutlinedInput'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import { styled } from '@mui/material/styles'
import FormHelperText from '@mui/material/FormHelperText'
import InputAdornment from '@mui/material/InputAdornment'
import Typography from '@mui/material/Typography'
import * as yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { getSession, signIn } from 'next-auth/react'
import { GetServerSidePropsContext } from 'next'
import { AUTH_LIMBO, AUTH_PROTECTED } from '../../lib/constants/endpoints'
import { useRouter } from 'next/router'
import * as Sentry from '@sentry/nextjs'
import LoginPageNotReady from '../../components/loginPageNotReady'
import {getRetailerEmail} from "../../repository/partner/partner-repository";

const BoxWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  width: '100%',
  maxWidth: 450,
  [theme.breakpoints.down('md')]: {
    maxWidth: 450
  },
  paddingTop: '20px'
}))

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(5).required()
})

interface FormData {
  email: string
  password: string
}

interface Props {
  legacyUser: boolean
  emailAddress: string | null
}

const LoginPage = ({legacyUser, emailAddress}: Props) => {
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState('')
  const router = useRouter()

  const defaultValues = {
    password: '',
    email: legacyUser && emailAddress ? emailAddress : ''
  }

  const {
    control,

    // setError,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(schema)
  })

  if (process.env.NEXT_PUBLIC_LOGIN_DISABLED === 'true' && process.env.NEXT_PUBLIC_ENVIRONMENT === 'live') {
    return <LoginPageNotReady />
  }

  const onSubmit = async (data: FormData) => {
    const { email, password } = data
    try {
      const res = await signIn('credentials', { email, password, redirect: false })
      if (res && res.ok) {
        router.push(AUTH_LIMBO.ADDITIONAL_SELECTION)
      } else {
        setErrorMsg('Faulty credentials')
      }
    } catch (e) {
      Sentry.captureException(e)
      console.log(e)
    }
  }

  return (
    <Box className='content-center' sx={{ backgroundColor: 'background.paper' }}>
      <Box
        sx={{
          p: 7,
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'background.paper'
        }}
      >
        <BoxWrapper>
          <Box>
            <Image
              src='https://cdn.mybestbrands.de/images/Newsletter/img/logo.png'
              alt='MYBESTBRANDS'
              className='float-center'
              width={400}
              height={50}
              layout='responsive'
              objectFit='contain'
              objectPosition={'50% 50%'}
              quality={100}
              priority={true}
            />
          </Box>
          <Box
            sx={{
              mb: 6,
              mt: 6,
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            {legacyUser && emailAddress ? (
                <>
                  <Typography sx={{ color: 'black', fontWeight: 'normal', textAlign: 'justify' }}>
                    MYBESTBRANDS partnerweb has been updated to a new version. From now on, please use this email address for login:
                  </Typography>
                  <Typography sx={{ color: 'green', fontWeight: 'bold', textAlign: 'center' }}>
                    {emailAddress}
                  </Typography>
                  <Typography sx={{ color: 'black', fontWeight: 'normal', textAlign: 'center' }}>
                    Your password remains the same.
                  </Typography>
                  <Typography sx={{ color: 'black', fontWeight: 'lighter', textAlign: 'center' }}>
                    After login you can create additional accounts if need be. If you forgot your password, use the "Forgot Password?" functionality.
                  </Typography>
                </>
            ) : <>
              <Typography sx={{ color: 'black', fontWeight: 'bold', textAlign: 'center' }}>Please log in.</Typography>
              <Typography sx={{ color: 'black', fontWeight: 'bold', textAlign: 'center' }}>
                If you haven't received credentials yet,
              </Typography>
              <Typography sx={{ color: 'black', fontWeight: 'bold', textAlign: 'center' }}>
                please contact your MYBESTBRANDS Key Account Manager
              </Typography>
            </> }
          </Box>
          <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
            {errorMsg && <Typography sx={{ color: 'error.main', textAlign: 'center', mb: 4 }}>{errorMsg}</Typography>}
            <FormControl fullWidth sx={{ mb: 4 }}>
              <Controller
                name='email'
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange, onBlur } }) => (
                  <TextField
                    autoFocus
                    label='Email'
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    error={Boolean(errors.email || errorMsg)}
                    placeholder='Please enter your email'
                  />
                )}
              />
              {errors.email && <FormHelperText sx={{ color: 'error.main' }}>{errors.email.message}</FormHelperText>}
            </FormControl>
            <FormControl fullWidth>
              <InputLabel htmlFor='auth-login-v2-password' error={Boolean(errors.password)}>
                Password
              </InputLabel>
              <Controller
                name='password'
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange, onBlur } }) => (
                  <OutlinedInput
                    value={value}
                    onBlur={onBlur}
                    label='Password'
                    onChange={onChange}
                    id='auth-login-v2-password'
                    error={Boolean(errors.password || errorMsg)}
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          edge='end'
                          onMouseDown={e => e.preventDefault()}
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                )}
              />
              {errors.password && (
                <FormHelperText sx={{ color: 'error.main' }} id=''>
                  {errors.password.message}
                </FormHelperText>
              )}
            </FormControl>
            <Box sx={{ mt: 4, display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'flex-end' }}>
              <Link passHref href='/forgot-password' legacyBehavior>
                <Typography component={MuiLink} className={''} variant='body2' sx={{ color: '#FF705F' }}>
                  Forgot Password?
                </Typography>
              </Link>
            </Box>
            <Box>
              <Button size='large' type='submit' variant='contained' sx={{ mb: 3 }}>
                Login
              </Button>
            </Box>
          </form>
        </BoxWrapper>
      </Box>
    </Box>
  )
}

export const getServerSideProps = async (context: GetServerSidePropsContext) => {
  const session = await getSession(context)

  if (session && session?.user?.userId && session?.user?.countryId) {
    return {
      redirect: {
        permanent: false,
        destination: `${AUTH_PROTECTED.MYAREA}`
      }
    }
  }

  const { legacy, retailer_id } = context?.query
  let emailAddress = null
  let legacyUser = false

  if (legacy && +legacy === 1 && retailer_id && +retailer_id > 0) {
    const getAddress = await getRetailerEmail(+retailer_id)
    if (getAddress) {
      legacyUser = true
      emailAddress = getAddress
    }
  }

  return {
    props: {
      legacyUser,
      emailAddress
    }
  }
}

export default LoginPage
